import styled from "styled-components"

import colors from "style/colors"

export const TextCentered = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;

  flex-direction: column;
  color: ${colors.dark.hex};

  @media (min-width: 1200px) {
    height: calc(100vh - 250px);
  }
`

export const Title = styled.h1`
  font-size: 42px;

  > strong {
    color: ${colors.primary.hex};
  }

  @media (min-width: 1200px) {
    font-size: 56px;
  }
`

export const SubTitle = styled.code`
  font-size: 16px;
  font-weight: 500;

  margin-top: 20px;
`

export const Slogan = styled.code`
  font-size: 16px;
  font-weight: 500;

  color: ${colors.primary.hex};
`
