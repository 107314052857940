import { FC } from "react"
import { Canvas as TCanvas } from "@react-three/fiber"

interface Props {
  [key: string]: any
  children: React.ReactNode
}

const Canvas: FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <TCanvas {...props}>
        {children}
      </TCanvas>
    </>
  )
}

export default Canvas
