import { FC } from "react"

import { IoHeart } from "react-icons/io5"

import * as S from "./styled"

interface Props {
  credits: string
}

const Credits: FC<Props> = ({ credits }) => {
  if (!credits) {
    return null
  }

  return (
    <S.Credits>
      <code>
        <a href={credits} target="_blank" rel="noreferrer">
          Model used from <br /> <strong>Sketchfab</strong>
        </a>
        <span>
          <IoHeart />
        </span>
      </code>
    </S.Credits>
  )
}

export default Credits
