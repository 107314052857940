import PortfolioScene from "components/Three/PortfolioScene/PortfolioScene"
import { FC, useMemo, useState, useEffect } from "react"
import NextButton from "./Buttons/Next"
import PreviousButton from "./Buttons/Previous"

import { Canvas } from "@react-three/fiber"

import store from "./store"

import * as S from "./styled"
import Credits from './Partials/Credits'
import Info from './Partials/Info'

interface Props {}

const PortfolioSlides: FC<Props> = () => {
  const [slide, setSlide] = useState(1)
  const [bgColor, setBgColor] = useState("#000")

  useEffect(() => {
    setBgColor(store.items[slide - 1].color)
  }, [slide])

  const onNextSlide = () => {
    const newSlide = slide + 1
    if (newSlide <= store.items.length) {
      setSlide(slide + 1)
    } else {
      setSlide(1)
    }
  }

  const onPreviousSlide = () => {
    const newSlide = slide - 1

    if (newSlide > 0) {
      setSlide(newSlide)
    } else {
      setSlide(store.items.length)
    }
  }

  const slideNumber = useMemo(() => {
    return slide < 10 ? `0${slide}` : slide
  }, [slide])

  const currentSlide = useMemo(() => {
    return store.items[slide - 1]
  }, [slide])

  return (
    <S.SlidesContainer backgroundColor={bgColor}>
      <S.Slides>
        <PreviousButton onClick={onPreviousSlide} />
        <NextButton onClick={onNextSlide} />

        <S.Counter>{slideNumber}</S.Counter>
        <S.Pattern></S.Pattern>

        <Canvas
          orthographic
          dpr={[1, 2]}
          shadows
          camera={{
            zoom: 75,
            position: [0, 0, 500]
          }}
        >
          <PortfolioScene slide={slide} slides={store.items} />
        </Canvas>

        {/* <TechnologiesUsed items={currentSlide.technologies} /> */}
        <Credits credits={currentSlide.credits} />
        <Info link={currentSlide.link} />
      </S.Slides>
    </S.SlidesContainer>
  )
}

export default PortfolioSlides
