import React, { useState } from "react"
import { useFrame } from "@react-three/fiber"

import { MeshDistortMaterial, useGLTF } from "@react-three/drei"
import { useSpring } from "@react-spring/core"
import { animated } from "@react-spring/three"
const AnimatedMaterial = animated(MeshDistortMaterial)

const WobbleSphere: React.FC = () => {
  const sphere = React.useRef<any>()

  const [mode, setMode] = useState(false)
  const [hovered, setHovered] = useState(false)

  const { nodes }: any = useGLTF(require("assets/models/geo.min.glb"), true)

  useFrame(() => {
    if (sphere.current) {
      sphere.current.rotation.x -= 0.005
      sphere.current.rotation.z += 0.0025
    }
  })

  // @ts-ignore
  const [{ wobble, coat, color, env }] = useSpring(
    {
      wobble: hovered ? 1.05 : 1,
      coat: mode && !hovered ? 0.04 : 0.25,
      env: mode && !hovered ? 0.4 : 0.5,
      color: !hovered ? "#FF4B3E" : mode ? "#FF4B3E" : "#210B2C",
      config: (n) => n === "wobble" && hovered && { mass: 4, tension: 500, friction: 10 }
    },
    [mode, hovered]
  )

  return (
    <group ref={sphere} scale={[150, 150, 150]} position={[0, 100, -500]}>
      <animated.mesh
        scale={wobble}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onPointerUp={() => {
          setMode(!mode)
        }}
        castShadow
        receiveShadow
      >
        <sphereBufferGeometry args={[0.6, 64, 64]} />
        {/* @ts-ignore */}
        <AnimatedMaterial
          color={color}
          envMapIntensity={env}
          clearcoat={coat}
          clearcoatRoughness={0}
          metalness={0.1}
          factor={50}
          speed={2}
        />
      </animated.mesh>
      <animated.mesh scale={wobble} geometry={nodes.geo.geometry} castShadow receiveShadow>
        <MeshDistortMaterial
          color="#210B2C"
          flatShading
          roughness={1}
          metalness={1}
          factor={15}
          speed={3}
          wireframe
        />
      </animated.mesh>
    </group>
  )
}

export default WobbleSphere
