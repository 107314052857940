import styled from "styled-components"
import colors from "style/colors"

export const TechnologiesUsed = styled.div`
  position: absolute;
  bottom: 30px;
  left: 20px;

  user-select: none;

  > p {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;

    color: rgba(${colors.white.rgb}, 0.5);
  }

  > span {
    display: block;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    color: ${colors.white.hex};
    text-transform: capitalize;
  }

  @media (min-width: 1200px) {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
`
export const Credits = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;

  user-select: none;

  color: ${colors.white.hex};

  a {
    color: inherit;

    strong {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  span {
    padding-left: 5px;
    color: red;
  }
`

export const Info = styled.div`
  position: absolute;

  bottom: 10px;
  right: 10px;

  color: ${colors.white.hex};

  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;

  svg {
    font-size: 22px;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    right: 20px;
  }
`

export const Private = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 12px;

  > svg {
    margin-right: 5px;
  }
`

export const Link = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120px;

  padding: 12px 6px;
  margin-bottom: 10px;
  color: ${colors.dark.hex};
  text-decoration: none;

  background-color: ${colors.white.hex};
  position: relative;

  transform-style: preserve-3d;

  font-size: 12px;
  letter-spacing: 1px;

  @media (min-width: 1200px) {
    width: 140px;

    padding: 18px 12px;
  }

  &:before {
    content: "";

    width: 100%;
    height: 100%;
    display: block;
    position: absolute;

    bottom: -5px;
    left: -5px;

    border: 1px solid ${colors.dark.hex};

    transform: translateZ(-1px);
    transition: 0.2s ease-in-out;
  }

  > svg {
    font-size: 12px;
    margin-left: 8px;
  }

  &:hover {
    &:before {
      bottom: 0;
      left: 0;
    }
  }
`
