import styled from "styled-components"

import colors from "style/colors"
import { animated } from "@react-spring/web"

export const Wrapper = styled.div`
  width: 100%;
  height: 95vh;

  position: relative;

  padding: 0 20px;
`

export const VerticalBlock = styled(animated.div)`
  position: absolute;

  left: 0;
  top: 0;

  width: 60px;
  height: 101%;

  z-index: 9;

  background-color: transparent;

  @media (min-width: 1200px) {
    left: 120px;
    top: 0;

    width: 200px;
    height: 100%;

    background-color: ${colors.white.hex};
  }
`

export const Title = styled.h2`
  display: block;
  font-size: 24px;
  font-weight: 700;
  color: ${colors.primary.hex};

  text-transform: uppercase;
  position: relative;

  margin: 0 auto;

  transform: rotate(90deg) translateX(-105%);

  > div {
    width: 70px;
    height: 1px;
    background-color: ${colors.dark.hex};
    position: absolute;

    top: 50%;
    right: -180px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 48px;

    transform: rotate(90deg) translateX(55%);

    > div {
      right: -210px;
    }
  }
`

export const PatternBlock = styled.div`
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 60%;
  height: 20%;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`

export const Pattern = styled.div`
  width: 100%;
  height: 100%;
  background-image: radial-gradient(rgba(${colors.dark.rgb}, 1) 1px, transparent 1px);
  background-size: calc(10 * 1px) calc(10 * 1px);
`
