import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
html {
  -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;

    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
`
