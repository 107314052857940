import { FC } from "react"

import { BiLeftArrowAlt } from 'react-icons/bi'

import * as S from "./styled"

interface Props {
  onClick: () => void
}

const PreviousButton: FC<Props> = ({ onClick }) => {
  return (
    <S.PreviousButton onClick={onClick}>
      <BiLeftArrowAlt />
      <span>Previous</span>
    </S.PreviousButton>
  )
}

export default PreviousButton
