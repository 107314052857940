import { FC } from "react"
import { Html, useProgress } from "@react-three/drei"
import { useSpring } from "@react-spring/web"

import { Wrapper, LoadingMessage, ProgressBar } from "./styled"

const ThreeLoader: FC = () => {
  const { progress } = useProgress()
  const [{ width }] = useSpring(() => ({ width: 0 }))

  return (
    <Html center>
      <Wrapper>
        <LoadingMessage>{Number(progress.toFixed(0))} / 100</LoadingMessage>
        <ProgressBar
          style={{
            width: width.to(() => Number(progress.toFixed(0)) * 2 + "px")
          }}
        />
        <LoadingMessage>Loading</LoadingMessage>
      </Wrapper>
    </Html>
  )
}

export default ThreeLoader
