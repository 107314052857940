import styled from "styled-components"

import colors from "style/colors"

const GAP = 20

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 80%;

  padding: 18px 12px;

  outline: 0;
  border: 0;
  background-color: transparent;

  color: ${colors.white.hex};
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;

  transform: translateY(-50%);
  transform-style: preserve-3d;
  z-index: 9;

  cursor: pointer;

  @media (min-width: 1200px) {
    top: 50%;
  }

  > span {
    width: 100%;
    display: block;
    text-align: center;
  }

  > svg {
    font-size: 22px;
  }
`

export const NextButton = styled(Button)`
  right: ${GAP}px;

  &::before {
    left: -5px;
  }

  &:hover {
    &::before {
      bottom: 0;
      left: 0;
    }
  }
`

export const PreviousButton = styled(Button)`
  left: ${GAP}px;

  &::before {
    right: -5px;
  }

  &:hover {
    &::before {
      bottom: 0;
      right: 0;
    }
  }
`
