import { FC, Suspense, useEffect, useState } from "react"

import * as THREE from "three"
import { useFrame, useThree } from "@react-three/fiber"
import ThreeLoader from "components/Loaders/ThreeLoader"
import { Html } from "@react-three/drei"

interface Props {
  slide: number
  slides: any[]
}

let doit: any

const PortfolioScene: FC<Props> = ({ slide, slides }) => {
  const { viewport, camera } = useThree()
  const { width: w } = viewport

  useEffect(() => {
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  useFrame(() => {
    camera.position.x = THREE.MathUtils.lerp(camera.position.x, w * (slide - 1), 0.25)
    camera.updateProjectionMatrix()
  })

  const onResize = () => {
    clearTimeout(doit)
    doit = setTimeout(onFinishResize, 1000)
  }

  const onFinishResize = () => {
    camera.updateProjectionMatrix()
    camera.updateMatrixWorld()
  }

  return (
    <>
      <Suspense fallback={<ThreeLoader />}>
        <ambientLight intensity={0.75} />

        {slides.map((item, index) => {
          if (item.project) {
            return <item.slide key={index} index={index} />
          } else {
            return (
              <item.slide
                key={index}
                index={index}
                name={item.name}
                description={item.description}
                notes={item.notes}
                imgUrl={item.imgUrl}
              />
            )
          }
        })}
      </Suspense>
    </>
  )
}

export default PortfolioScene
