import styled from "styled-components"
import colors from "style/colors"
import sizes from "style/sizes"

export const Container = styled.div`
  margin: 40px 0;
  padding: 0 ${sizes.padding.mobile}px;

  @media (min-width: 768px) {
    padding: 0 ${sizes.padding.tablet}px;
  }

  @media (min-width: 1200px) {
    padding: 0 ${sizes.padding.desktop}px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 80px;
  overflow-x: hidden;
`

export const Title = styled.h3`
  font-size: 36px;
  font-weight: 900;
  display: inline-flex;
  position: relative;

  text-transform: uppercase;
  letter-spacing: 1px;

  > span {
    position: absolute;
    top: 50%;
    right: -120%;

    display: block;

    width: 100%;
    height: 1px;

    background-color: ${colors.primary.hex};
  }
`

export const SubTitle = styled.h4`
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  color: ${colors.primary.hex};
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
export const Column = styled.div`
  flex: 1;
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 400px;

  margin: 0 auto 80px auto;

  position: relative;
  transform-style: preserve-3d;

  @media (min-width: 1200px) {
    width: 60%;
    height: 600px;

    margin: 0 auto;
  }

  &::before {
    content: "";
    display: block;
    height: 60%;
    width: 100%;

    position: absolute;

    bottom: -30px;
    left: -30px;

    background-image: radial-gradient(rgba(${colors.primary.rgb}, 1) 1px, transparent 1px);
    background-size: calc(10 * 1px) calc(10 * 1px);

    transform: translateZ(-1px);
  }
`
export const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`

export const TextWrapper = styled.div``

export const Welcome = styled.p`
  font-size: 48px;
  font-weight: 900;
`
export const AboutMe = styled.p`
  font-size: 22px;
  margin-top: 20px;
  > strong {
    color: ${colors.primary.hex};
  }
`

export const TechnologiesList = styled.div`
  margin-top: 40px;
`

export const TechGroup = styled.div`
  margin-top: 20px;

  img {
    margin-right: 8px;
    margin-bottom: 4px;
  }
`

export const TechGroupTitle = styled.code`
  display: block;
  text-transform: uppercase;

  margin-bottom: 8px;
`
