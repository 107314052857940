import { FC } from "react"

import * as S from "./styled"

const TechnologiesList: FC = () => {
  return (
    <S.TechnologiesList>
      <S.TechGroupTitle style={{ fontSize: 16 }}>
        <strong>Technologies I'm working with:</strong>
      </S.TechGroupTitle>

      <S.TechGroup>
        <S.TechGroupTitle>Mostly used</S.TechGroupTitle>
        <img
          src="https://camo.githubusercontent.com/1a13f0d41aed552fc4b471da3c9dba7b00302492385b6deac8f23e58b0dc6d9d/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4a6176615363726970742d4546443831433f6c6f676f3d6a617661736372697074266c6f676f436f6c6f723d626c61636b267374796c653d666f722d7468652d6261646765"
          alt="JavaScript"
          data-canonical-src="https://img.shields.io/badge/JavaScript-EFD81C?logo=javascript&amp;logoColor=black&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/5eb59ad663decc80dd7dd1b8a5ebe44095582ab23af770e62eea2dd229b31911/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f547970655363726970742d3246373443303f6c6f676f3d74797065736372697074266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="TypeScript"
          data-canonical-src="https://img.shields.io/badge/TypeScript-2F74C0?logo=typescript&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/6c1c281dcd8fe6694d35fe734aafd94f14bc16f0268e04a86db0bd3b03de8067/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f52656163742d3030443146373f6c6f676f3d7265616374266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="React"
          data-canonical-src="https://img.shields.io/badge/React-00D1F7?logo=react&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/eb5626c3eba7a9ed168fbde9ee4c95c4b2deb92b9666fbb84fcfe05607b979ed/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f52656163745f4e61746976652d3030443146373f6c6f676f3d7265616374266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="ReactNative"
          data-canonical-src="https://img.shields.io/badge/React_Native-00D1F7?logo=react&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/710f2ee420a1a534120a688562c7dcda198d779e6d47a974ab31131328e08463/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f5675652d3345423237463f6c6f676f3d7675652e6a73266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="Vue"
          data-canonical-src="https://img.shields.io/badge/Vue-3EB27F?logo=vue.js&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/8a42b963c074cf06acf25b5d65cf85f6bdbf1fe67e3f7a041fc447bdec5aecb7/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f736f636b65742e696f2d2532333031303130312e7376673f267374796c653d666f722d7468652d6261646765266c6f676f3d736f636b65742e696f266c6f676f436f6c6f723d7768697465"
          alt="Socket.io"
          data-canonical-src="https://img.shields.io/badge/socket.io-%23010101.svg?&amp;style=for-the-badge&amp;logo=socket.io&amp;logoColor=white"
        />
        <img
          src="https://camo.githubusercontent.com/a8c571d5dc6c3a75717d2c218405ab1634ff0c8acb0cc57b6e8ecf9eaa7a0250/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f5374796c65645f436f6d706f6e656e74732d4537413639333f6c6f676f3d7374796c65642d636f6d706f6e656e7473266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="Styled-Components"
          data-canonical-src="https://img.shields.io/badge/Styled_Components-E7A693?logo=styled-components&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/63805fde03cf130a632ee8bbfca66ca84e0e1858bd718bc726e3bbaef2e5ec4d/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f534153532d4337363339353f6c6f676f3d73617373266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="SASS"
          data-canonical-src="https://img.shields.io/badge/SASS-C76395?logo=sass&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/9f23a07d480916bf81dd4adec5cf547190450ecbc7ced366c7ecba3e114172f6/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f435353332d4337363339353f6c6f676f3d63737333266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="CSS3"
          data-canonical-src="https://img.shields.io/badge/CSS3-C76395?logo=css3&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/e05f322dcbf45fbbec58733422ab7ac29e0cbc3a96a3b2cc30c5e80b86320f4c/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f48544d4c352d32334533344632363f6c6f676f3d68746d6c35266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="HTML5"
          data-canonical-src="https://img.shields.io/badge/HTML5-23E34F26?logo=html5&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/97e8bf05514242637579741c158767783a4032048ff4db9e25fe4c77905a5b94/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f73746f7279626f6f6b2d2532334646343738352e7376673f267374796c653d666f722d7468652d6261646765266c6f676f3d73746f7279626f6f6b266c6f676f436f6c6f723d7768697465"
          alt="StoryBook"
          data-canonical-src="https://img.shields.io/badge/storybook-%23FF4785.svg?&amp;style=for-the-badge&amp;logo=storybook&amp;logoColor=white"
        />
      </S.TechGroup>
      <S.TechGroup>
        <S.TechGroupTitle>Still learning</S.TechGroupTitle>
        <img
          src="https://camo.githubusercontent.com/e0c3c292f976f02b18f86ba7314b80fb4d39272be90c8f0eb365045a44058875/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f74687265652e6a732d2532333030303030302e7376673f267374796c653d666f722d7468652d6261646765266c6f676f3d74687265652e6a73266c6f676f436f6c6f723d7768697465"
          alt="ThreeJS"
          data-canonical-src="https://img.shields.io/badge/three.js-%23000000.svg?&amp;style=for-the-badge&amp;logo=three.js&amp;logoColor=white"
        />
        <img
          src="https://camo.githubusercontent.com/b0b3d1b1e355014b5a7fffc978bbe9ff1aa88bb15d21e6eddaa48fd9e3b2cdad/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f5233462d2532333030303030302e7376673f267374796c653d666f722d7468652d6261646765266c6f676f3d74687265652e6a73266c6f676f436f6c6f723d7768697465"
          alt="ReactThreeFiber"
          data-canonical-src="https://img.shields.io/badge/R3F-%23000000.svg?&amp;style=for-the-badge&amp;logo=three.js&amp;logoColor=white"
        />
        <img
          src="https://img.shields.io/badge/Cesium-6DABE4?style=for-the-badge&amp;logo=cesium&amp;logoColor=white"
          alt="Cesium"
        />
        <img
          src="https://img.shields.io/badge/Resium-6DABE4?style=for-the-badge&amp;logo=cesium&amp;logoColor=white"
          alt="Resium"
        />
      </S.TechGroup>

      <S.TechGroup>
        <S.TechGroupTitle>Mostly for personal use</S.TechGroupTitle>
        <img
          src="https://camo.githubusercontent.com/cfe08cb17e22248a8821c69742bbc9f02a2b85249d7d75b2db381ca827d6e544/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4e6f64654a532d3837424630303f6c6f676f3d6e6f64652e6a73266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="NodeJS"
          data-canonical-src="https://img.shields.io/badge/NodeJS-87BF00?logo=node.js&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/9d071297e29141862150bf1117513cb9b4ba0e6eb0fead69831731b9a39c239e/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4d6f6e676f44422d3433393734333f6c6f676f3d6d6f6e676f6462266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="MongoDB"
          data-canonical-src="https://img.shields.io/badge/MongoDB-439743?logo=mongodb&amp;logoColor=white&amp;style=for-the-badge"
        />
        <img
          src="https://camo.githubusercontent.com/2539ff467e7248a95e2a6960d3098dea55d7ae2df495abb7b95d5259d4288ae6/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6578706f2d2532333030303032302e7376673f267374796c653d666f722d7468652d6261646765266c6f676f3d6578706f266c6f676f436f6c6f723d7768697465"
          alt="Expo"
          data-canonical-src="https://img.shields.io/badge/expo-%23000020.svg?&amp;style=for-the-badge&amp;logo=expo&amp;logoColor=white"
        />
        <img
          src="https://camo.githubusercontent.com/9f6e942a78452441d72ceaa868c4635c832e718fc684431515949bbff8f54310/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f576f726470726573732d3035373239373f6c6f676f3d776f72647072657373266c6f676f436f6c6f723d7768697465267374796c653d666f722d7468652d6261646765"
          alt="WordPress"
          data-canonical-src="https://img.shields.io/badge/Wordpress-057297?logo=wordpress&amp;logoColor=white&amp;style=for-the-badge"
        />
      </S.TechGroup>
    </S.TechnologiesList>
  )
}

export default TechnologiesList
