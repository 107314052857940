import { FC } from "react"

import { IoLockClosedOutline } from "react-icons/io5"
import { FaExternalLinkAlt } from "react-icons/fa"

import * as S from "./styled"

interface Props {
  link?: string
}

const Info: FC<Props> = ({ link }) => {
  return (
    <S.Info>
      {link ? (
        <S.Link href={link} target="_blank" rel="noreferrer">
          <span>Preview</span> <FaExternalLinkAlt />
        </S.Link>
      ) : (
        <S.Private>
          <IoLockClosedOutline /> <code>Private project</code>
        </S.Private>
      )}
    </S.Info>
  )
}

export default Info
