import styled from "styled-components"

import colors from "style/colors"

export const ContactMeButton = styled.button`
  width: 100%;
  height: 100%;

  background-color: ${colors.dark.hex};

  border: 0;
  outline: 0;

  font-family: "Montserrat", sans-serif;
  color: ${colors.white.hex};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  cursor: pointer;

  position: relative;

  transition: 0.33s ease-in-out;

  @media (min-width: 1200px) {
    font-size: 14px;
  }

  &::before {
    content: "";
    display: block;

    position: absolute;
    z-index: -1;
    bottom: -5px;
    right: -5px;

    width: 99%;
    height: 99%;
    border: 1px solid ${colors.primary.hex};

    border-radius: inherit;
    transition: inherit;
  }

  &:hover {
    background-color: ${colors.primary.hex};

    ::before {
      bottom: 0px;
      right: 0px;
    }
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  > div {
    position: absolute;
    top: 50%;
    text-align: center;
    left: 0;
    right: 0;
  }
`
