import styled from "styled-components"

import colors from "style/colors"

export const SocialMediaLinksFixed = styled.div`
  position: absolute;
  bottom: 0px;
  right: 20px;

  width: 40px;

  text-align: center;

  font-weight: bold;
`

export const SocialMediaWrapper = styled.div`
  margin-bottom: 10px;

  > div {
    width: 30px;
    height: 30px;

    margin: 0 auto 10px auto;

    border: 1px solid rgba(${colors.dark.rgb}, 0.05);
    box-shadow: 0 0 15px 0 rgba(${colors.dark.rgb}, 0.05);
    background-color: ${colors.white.hex};

    cursor: pointer;

    position: relative;

    &::before {
      content: "";
      display: block;

      position: absolute;
      top:0;
      left: 0;
      z-index: -1;

      width: 100%;
      height: 100%;

      background-color: ${colors.primary.hex};

      transform: translate(0, 0);
      transition: 0.3s ease-in-out;
    }

    &:hover {
      color: ${colors.primary.hex};

      &::before {
        transform: translate(4px, 4px);
      }
    }
  }

  > div > a {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: inherit;
    text-decoration: none;

    transition: 0.25s ease-in-out;
  }
`

export const Scroll = styled.div`
  padding-top: 20px;
  padding-bottom: 100px;

  > span {
    font-size: 10px;
    display: inline-block;
    text-transform: uppercase;

    color: ${colors.dark.hex};

    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: rotate(90deg) translate(-1px, 0);
  }

  > div {
    position: absolute;
    bottom: -70px;
    left: 50%;
    width: 1px;
    height: 150px;

    background-color: ${colors.dark.hex};

    margin: 0 auto;

    transform: translateX(-50%);
  }
`
