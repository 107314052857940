import styled from "styled-components"
import sizes from "style/sizes"

export const Page = styled.div``

interface SectionProps {
  disablePadding?: boolean
}
export const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  padding: 0 ${({ disablePadding }) => (disablePadding ? 0 : sizes.padding.mobile)}px;

  @media (min-width: 768px) {
    padding: 0 ${({ disablePadding }) => (disablePadding ? 0 : sizes.padding.tablet)}px;
  }

  @media (min-width: 1200px) {
    padding: 0 ${({ disablePadding }) => (disablePadding ? 0 : sizes.padding.desktop)}px;
    flex-direction: row;
  }
`

export const Column = styled.div`
  width: 100%;

  @media (min-width: 1200px) {
    width: 50%;
  }
`


