const colors = {
  primary: {
    hex: '#FF4B3E',
    rgb: '255,75,62'
  },
  primaryDarker: {
    hex: '#FF1B0A',
    rgb: '255,27,10'
  },
  dark: {
    hex: '#210B2C',
    rgb: '33,11,44'
  },
  grey: {
    hex: '#818D92',
    rgb: '129,141,146'
  },
  white: {
    hex: '#FFFFFF',
    rgb: '255,255,255'
  }
}

export default colors