import { FC, Suspense } from "react"
import WobbleSphere from "./WobbleSphere"
import Canvas from "./Canvas"

import styled from "styled-components"

import { ContactShadows, PerspectiveCamera, Float } from "@react-three/drei"
import ThreeLoader from "components/Loaders/ThreeLoader"

const Wrapper = styled.div`
  width: 100%;
  height: 60vh;

  position: relative;
  z-index: 9;

  @media (min-width: 1200px) {
    height: 100%;
  }
`

export const Shapes = styled.div`
  position: absolute;

  right: 5%;
  bottom: 35%;

  z-index: -1;

  @media (min-width: 1200px) {
    right: 75px;
    bottom: 40%;
  }
`

const HeroScene: FC = () => {
  return (
    <Wrapper>
      <Canvas>
        <PerspectiveCamera makeDefault position={[0, 0, 4]} fov={75}></PerspectiveCamera>
        <ambientLight />
        <Suspense fallback={<ThreeLoader />}>
          <Float speed={0.75} rotationIntensity={0.5} floatIntensity={1.5}>
            <WobbleSphere />
          </Float>
        </Suspense>
        <ContactShadows
          rotation-x={Math.PI / 2}
          position={[0, -1.4, 0]}
          opacity={0.8}
          width={15}
          height={15}
          blur={2.5}
          far={1.6}
        />
      </Canvas>
      <Shapes>
        <img src={require("assets/images/shapes.png")} alt="shapes" />
      </Shapes>
    </Wrapper>
  )
}

export default HeroScene
