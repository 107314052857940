import { FC, useEffect, useState } from "react"

import { useSpring } from "@react-spring/web"

import PortfolioSlides from "components/PortfolioSlides"
import { Section } from "../../Home.styled"

import * as S from "./styled"

const PortfolioSection: FC = () => {
  const [w, setW] = useState(window.innerWidth)
  const [h, setH] = useState(window.innerHeight)
  const [{ y }, api] = useSpring(() => ({ y: w < 1200 ? 0 : -40 }))

  useEffect(() => {
    document.addEventListener("scroll", onScroll)
    document.addEventListener("resize", onResize)

    return () => {
      document.removeEventListener("scroll", onScroll)
      document.removeEventListener("resize", onResize)
    }
  }, [])

  const onClick = () => {
    document.querySelector('#profile')?.scrollIntoView({ behavior: 'smooth' })
  }

  const onScroll = () => {
    if (w < 1200) {
      return
    }
    if (window.pageYOffset <= h) {
      const value = -40 + window.pageYOffset * 0.05
      api.start({ y: value < 0 ? value : 0 })
    } else {
      api.start({ y: 0 })
    }
  }

  const onResize = () => {
    setW(window.innerWidth)
    setH(window.innerHeight)
  }

  return (
    <Section id="profile" disablePadding style={{ padding: '20px 0'}}>
      <S.Wrapper>
        <S.VerticalBlock
          style={{
            transform: y.to((v: number) => `translateY(${v}%`)
          }}
        >
          <S.Title onClick={onClick}>
            Portfolio
            <div></div>
          </S.Title>

          <S.PatternBlock>
            <S.Pattern />
          </S.PatternBlock>
        </S.VerticalBlock>

        <PortfolioSlides />
      </S.Wrapper>
    </Section>
  )
}

export default PortfolioSection
