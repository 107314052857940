import styled from "styled-components"
import colors from "style/colors"
import sizes from "style/sizes"

export const Container = styled.div`
  margin: 120px 0 40px 0;
  padding: 0 ${sizes.padding.mobile}px;

  overflow: hidden;

  @media (min-width: 768px) {
    padding: 0 ${sizes.padding.tablet}px;
  }

  @media (min-width: 1200px) {
    padding: 0 ${sizes.padding.desktop}px;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 80px;
  overflow-x: hidden;
`

export const Title = styled.h3`
  font-size: 36px;
  font-weight: 900;

  display: inline-flex;
  position: relative;

  text-transform: uppercase;
  letter-spacing: 1px;

  > span {
    position: absolute;
    top: 50%;
    right: -120%;

    display: block;

    width: 100%;
    height: 1px;

    background-color: ${colors.primary.hex};
  }
`

export const SubTitle = styled.h4`
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  color: ${colors.primary.hex};

  a {
    color: ${colors.dark.hex};
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const GridItem = styled.div`
  flex: 1;
  min-width: 100%;
  max-width: 100%;
  margin: 20px 0;

  height: 350px;

  background-color: ${colors.white.hex};
  border: 1px solid rgba(${colors.primary.rgb}, 0.25);
  box-shadow: 0 0 15px 0 rgba(${colors.dark.rgb}, 0.05);

  padding: 20px;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transform-style: preserve-3d;
  transition: 0.2s ease-in-out;

  @media (min-width: 990px) {
    min-width: calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    margin: 10px;
  }

  @media (min-width: 1400px) {
    min-width: calc(25% - 20px);
    max-width: calc(25% - 20px);
    margin: 10px;
  }

  &:before {
    content: "";

    width: 100%;
    height: 50%;
    display: block;
    position: absolute;

    bottom: 0px;
    right: 0px;

    /* border: 1px solid ${colors.dark.hex}; */
    background-color: ${colors.dark.hex};

    transform: translateZ(-1px);
    transition: 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      bottom: -8px;
      right: -8px;
    }
  }
`
export const ItemTitle = styled.h2`
  font-size: 18px;
  color: ${colors.primary.hex};

  margin-top: 10px;
  margin-bottom: 20px;
`

export const ItemDescription = styled.div`
  font-size: 14px;
`
export const ItemActions = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ItemPrivate = styled.div`
margin-top: 20px;
`

export const ItemLink = styled.a`
  color: ${colors.dark.hex};
  font-size: 18px;
  margin-right: 10px;

  transition: 0.2s ease-in-out;

  &:hover {
    color: ${colors.primary.hex};
  }
`

export const ItemDemoBtn = styled.a`
  margin-right: 10px;

  display: inline-flex;
  align-items: center;

  padding: 10px 20px;
  margin: 0 0 0 auto;

  outline: 0;
  border: 1px solid ${colors.dark.hex};

  color: ${colors.dark.hex};
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  position: relative;

  cursor: pointer;

  transition: 0.3s ease-in-out;

  &:hover {
    border-color: ${colors.primary.hex};
    color: ${colors.primary.hex};
  }

  > svg {
    margin-left: 5px;
    margin-top: -1px;
  }
`
