import { FC } from "react"

import { IoLogoGithub, IoLogoLinkedin, IoMail, IoLogoCodepen } from "react-icons/io5"
import * as S from "./styled"

const SocialMediaAndScroll: FC = () => {
  return (
    <S.SocialMediaLinksFixed>
      <S.SocialMediaWrapper>
        <div>
          <a href="mailto:hello@kodeo.lt" target="_blank" rel="noreferrer">
            <IoMail />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/%C5%BEymantas-venclauskas/"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoLinkedin />
          </a>
        </div>
        <div>
          <a href="https://github.com/lazygeekpanda/lazygeekpanda" target="_blank" rel="noreferrer">
            <IoLogoGithub />
          </a>
        </div>
        <div>
          <a href="https://codepen.io/lazygeekpanda" target="_blank" rel="noreferrer">
            <IoLogoCodepen />
          </a>
        </div>
      </S.SocialMediaWrapper>

      <S.Scroll>
        <span>Scroll</span>

        <div></div>
      </S.Scroll>
    </S.SocialMediaLinksFixed>
  )
}

export default SocialMediaAndScroll
