import styled from "styled-components"
import colors from "style/colors"
import sizes from "style/sizes"

export const Header = styled.header`
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${sizes.padding.mobile}px;

  @media (min-width: 768px) {
    padding: 0 ${sizes.padding.tablet}px;
  }

  @media (min-width: 1200px) {
    padding: 0 ${sizes.padding.desktop}px;
  }
`

interface ColumnProps {
  align: "start" | "end"
  flex: number
}
export const Column = styled.div<ColumnProps>`
  flex: ${(props) => props.flex};

  display: inline-flex;
  align-items: center;
  justify-content: flex-${(props) => props.align};
`

export const Brand = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled.div`
  width: 40px;
  height: 40px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: 300;

  background-color: ${colors.primary.hex};
  color: ${colors.white.hex};
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;

  @media (min-width: 1200px) {
    font-size: 24px;
    margin-left: 15px;
  }
`

export const ContactMe = styled.div`
  width: 120px;
  height: 35px;

  margin: 0 0 0 10px;

  @media (min-width: 1200px) {
    width: 150px;
    height: 45px;
    margin: 0 0 0 30px;
  }
`

export const BurgerMenu = styled.div`
  width: 45px;
  height: 45px;

  padding: 14px 10px;

  background-color: ${colors.white.hex};
  box-shadow: 0 0 20px 1px rgba(${colors.dark.rgb}, 0.05);

  border: 1px solid rgba(${colors.dark.rgb}, 0.05);

  cursor: pointer;

  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover > div {
    width: 100%;
    background-color: ${colors.primary.hex};
  }
`

interface BurgerMenuLineProps {
  width: number
  position?: "right"
}
export const BurgerMenuLine = styled.div<BurgerMenuLineProps>`
  width: ${(props) => props.width}%;
  height: 1px;

  background-color: ${colors.dark.hex};
  transition: 0.22s ease-in-out;

  ${(props) =>
    props.position === "right"
      ? {
          margin: "0 0 0 auto"
        }
      : {}}
`
