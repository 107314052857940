import { FC, useEffect, useState } from "react"

import RootLoader from "components/Loaders/RootLoader"
import Home from "pages/Home"

const App: FC = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initApp()
  }, [])

  const initApp = () => {
    /* Well nothing todo here */
    // setTimeout(() => setLoading(false), 1000)
  }

  if (loading) {
    return <RootLoader />
  }

  return <Home />
}

export default App
