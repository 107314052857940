import { FC } from "react"

import TechnologiesList from './TechnologiesList'
import * as S from "./styled"

const AboutMe: FC = () => {
  return (
    <S.Container>
      <S.TitleWrapper>
        <S.Title>
          About Me <span></span>
        </S.Title>
        <S.SubTitle>Get to know me</S.SubTitle>
      </S.TitleWrapper>

      <S.FlexRow>
        <S.Column>
          <S.ImageWrapper>
            <S.Image src={require("assets/images/photo.jpg")} />
          </S.ImageWrapper>
        </S.Column>
        <S.Column>
          <S.TextWrapper>
            <S.Welcome>Hello!</S.Welcome>

            <S.AboutMe>
              My name is Žymantas Venclauskas, I'm currently living and working in Klaipėda,
              Lithuania as both full-time front-end developer at <strong>Kabal</strong> and
              freelance software developer - <strong>Kodeo</strong>.
            </S.AboutMe>
            <S.AboutMe>
              I'm a self-taught developer, who loves solving new challenges. I'm always looking for
              new opportunities to learn and grow.
            </S.AboutMe>

           <TechnologiesList />
          </S.TextWrapper>
        </S.Column>
      </S.FlexRow>
    </S.Container>
  )
}

export default AboutMe
