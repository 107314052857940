import styled from "styled-components"
import colors from "style/colors"
import sizes from "style/sizes"

export const Footer = styled.footer`
  margin-top: 200px;
  margin-bottom: 20px;
  padding: 0 ${sizes.padding.mobile}px;

  position: relative;

  @media (min-width: 768px) {
    padding: 0 ${sizes.padding.tablet}px;
  }

  @media (min-width: 1200px) {
    padding: 0 ${sizes.padding.desktop}px;
  }
`

export const Message = styled.div`
  position: relative;

  h3 {
    font-size: 24px;
    margin-bottom: 10px;

    color: ${colors.primary.hex};

    @media (min-width: 990px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 14px;
  }

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 80%;
    position: absolute;
    top: 80%;
    left: 5%;
    background-image: radial-gradient(${colors.primary.hex} 1px, transparent 1px);
    background-size: calc(10 * 1px) calc(10 * 1px);

    @media (min-width: 990px) {
      height: 100%;
      width: 40%;

      top: -30px;
      left: 50%;
    }
  }
`

export const Contacts = styled.div`
  > a {
    color: ${colors.dark.hex};
    font-weight: 700;
    display: block;
    margin: 20px 0 20px 0;
    font-size: 36px;
    transition: 0.22s ease-in-out;

    &:hover {
      color: ${colors.primary.hex};
    }

    @media (min-width: 990px) {
      font-size: 48px;
    }
  }

  > a:last-child {
    font-size: 24px;
  }
`

export const Float = styled.div`
  display: block;
  height: 60%;
  width: 80%;
  position: absolute;
  top: 140%;
  left: 10%;
  background-color: ${colors.dark.hex};
  color: ${colors.white.hex};

  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    color: inherit;
    font-size: 20px;
    margin: 0 10px;
  }

  @media (min-width: 990px) {
    height: 60%;
    width: 45%;

    top: 50%;
    left: 53%;
  }
`

export const Rights = styled.div`
  margin-top: 200px;

  @media (min-width: 990px) {
    margin-top: 100px;
  }
`
