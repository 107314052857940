import { FC } from "react"
import HeroScene from "components/Three/HeroScene"

import * as CommonS from "../../Home.styled"
import * as S from "./styled"

const HeroSection: FC = () => {
  return (
    <CommonS.Section id="home">
      <CommonS.Column>
        <S.TextCentered>
          <S.Title>
            <span>Hello,</span> <br /> my name is <strong>Žymantas</strong>.
          </S.Title>
          <S.SubTitle>I'm a freelance Software Developer</S.SubTitle>
          <S.Slogan>And I love creating beautiful user experiences</S.Slogan>
        </S.TextCentered>
      </CommonS.Column>
      <CommonS.Column>
        <HeroScene />
      </CommonS.Column>
    </CommonS.Section>
  )
}

export default HeroSection
