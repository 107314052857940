import { FC } from "react"
import { BiRightArrowAlt } from 'react-icons/bi'
import * as S from "./styled"

interface Props {
  onClick: () => void
}

const NextButton: FC<Props> = ({ onClick }) => {
  return (
    <S.NextButton onClick={onClick}>
      <span>Next</span>
      <BiRightArrowAlt />
    </S.NextButton>
  )
}

export default NextButton
