import { FC } from "react"

import Header from "components/Layout/Header"
import Footer from "components/Layout/Footer"

import HeroSection from "./Sections/Hero/Hero"
import PortfolioSection from "./Sections/Portfolio"
import AboutMeSection from "./Sections/AboutMe"
import ExperimentsSection from "./Sections/Experiments"

import * as S from "./Home.styled"
import SocialMediaAndScroll from "components/SocialMediaAndScroll"

const Home: FC = () => {
  return (
    <>
      <S.Page>
        <Header />

        <HeroSection />
        <PortfolioSection />
        <AboutMeSection />
        <ExperimentsSection />

        <SocialMediaAndScroll />

        <Footer />
      </S.Page>
    </>
  )
}

export default Home
