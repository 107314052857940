import { LazyExoticComponent, NamedExoticComponent, lazy } from "react"
export interface Project {
  id: string
  name: string
  color: string
  link?: string
  github?: string
  technologies: string[]
  project?: boolean
  credits: string
  slide: LazyExoticComponent<
    NamedExoticComponent<{
      index: number
    }>
  >
}

export interface Website {
  id: string
  name: string
  color: string
  description: string
  link: string
  imgUrl: string
  notes: string
  project?: boolean
  credits: string
  slide: LazyExoticComponent<
    NamedExoticComponent<{
      index: number
      name: string
      description: string
      notes: string
      imgUrl: string
    }>
  >
}

const projects: Project[] = [
  {
    id: "audio",
    name: "Audio Visualizer",
    color: "#43CF6C",
    technologies: ["react", "three", "AudioContext"],
    // @ts-ignore
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/AudioVisualizer")),
    link: "https://lazygeekpanda.github.io/audio-visualizer-js/",
    github: "https://github.com/lazygeekpanda/audio-visualizer-js",
    project: true,
    credits: 'https://sketchfab.com/3d-models/casette-tape-29082387aa9c4266803754104f0b7e03',
  },
  {
    id: "bloons",
    name: "Balloon Live",
    color: "#F85A3E",
    technologies: ["react", "redux", "firebase", "react router", "cesium + resium"],
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/BalloonLive")),
    link: "https://bloons.kodeo.lt",
    project: true,
    credits: 'https://sketchfab.com/3d-models/turkish-drone-bayraktar-9e313e94d5584d73a92835a9979744c6'
  },
  {
    id: "tubular",
    name: "Tubular Library",
    color: "#6153CC",
    technologies: ["vue", "vuex", "chartJS", "dropzone", "HTML to PDF"],
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/TubularLibrary")),
    project: true,
    credits: 'https://sketchfab.com/3d-models/rarp-oil-rig-9b6c23316ab8457e9929ef74eae5e7b1'
  },
  {
    id: "lookahead",
    name: "Lookahead 2.0",
    color: "#446DF6",
    technologies: ["react", "redux", "MUI"],
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/Lookahead")),
    project: true,
    credits: 'https://sketchfab.com/3d-models/driller-cc521221251a4c87b0cb429f98de882c'
  },
  {
    id: "pob",
    name: "Personnel on Board",
    color: "#B10F2E",
    technologies: ["react", "vue", "redux", "vuex", "MUI"],
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/POB")),
    project: true,
    credits: 'https://sketchfab.com'
  }
]

const websites: Website[] = [
  {
    id: "realtex",
    name: "Realtex",
    color: "#E6007E",
    description:
      "Real estate agency - Realtex. A young, professional and competent team providing efficient and qualified real estate services.",
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/Website")),
    link: "http://realtex.lt/",
    imgUrl: require("assets/images/realtex.jpg"),
    notes: "Worked on UI and some backend features with Laravel.",
    project: false,
    credits: ''
  },
  {
    id: "indreVilke",
    name: "Indrė Vilkė",
    color: "#47581D",
    description: "Made a simple WordPress website. Later on I did some small changes to new a theme as well as introducing to theme's options.",
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/Website")),
    link: "https://indrevilke.lt/",
    imgUrl: require("assets/images/photography.jpg"),
    project: false,
    notes: "",
    credits: ''
  },
  {
    id: "giedrePsichologe",
    name: "Giedrė Psichologė",
    color: "#CA9B52",
    description: "Made simple WordPress website.",
    slide: lazy(() => import("components/Three/PortfolioScene/Projects/Website")),
    link: "http://xn--giedrepsicholog-q8b.lt/",
    imgUrl: require("assets/images/website.jpg"),
    project: false,
    notes: "",
    credits: ''
  }
]

const state = {
  items: [...projects, ...websites]
}

export default state
