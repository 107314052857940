import { FC } from "react"
import ContactMeButton from "components/Buttons/ContactMeButton"

import * as S from "./Header.styled"

const Header: FC = () => {
  return (
    <S.Header>
      <S.Column align="start" flex={2}>
        <S.Brand>
          <S.Logo>k.</S.Logo>
          <S.Title>kodeo</S.Title>
        </S.Brand>
      </S.Column>
      <S.Column align="end" flex={10}>
        <S.ContactMe>
          <ContactMeButton />
        </S.ContactMe>

        {/* <S.BurgerMenu>
          <S.BurgerMenuLine width={60}></S.BurgerMenuLine>
          <S.BurgerMenuLine width={100}></S.BurgerMenuLine>
          <S.BurgerMenuLine width={60} position="right"></S.BurgerMenuLine>
        </S.BurgerMenu> */}
      </S.Column>
    </S.Header>
  )
}

export default Header
