import styled from "styled-components"
import { animated } from "@react-spring/web"
import colors from "style/colors"

export const RootLoader = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: ${colors.primary.hex};

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;

  font-size: 72px;
  font-weight: 700;
  text-transform: uppercase;
`

export const Wrapper = styled.div`
  text-align: center;

  width: 200px;
`

export const ProgressBar = styled(animated.div)`
  height: 3px;

  background-color: ${colors.primary.hex};

  margin-right: auto;
`

export const LoadingMessage = styled.p`
  margin: 6px 0;

  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`
