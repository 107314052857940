interface Experiment {
  id: string
  name: string
  description: string
  tech: string[]
  private: boolean
  githubUrl?: string
  url?: string
}

const experiments: Experiment[] = [
  {
    id: "corvette-c8",
    name: "Corvette C8 3D",
    description: "Corvette C8 car visualization following tutorial in React and reversing to vanilla JS and ThreeJS only.",
    private: false,
    tech: ['ThreeJS'],
    githubUrl: "https://github.com/lazygeekpanda/corvette-c8-threejs-exp",
    url: "https://corvette-c8-exp.kodeo.lt/"
  },
  {
    id: "audio-3d",
    name: "Audio Visualizer",
    description: "Visualizing audio tracks in 3D environment.",
    private: false,
    tech: ['React', 'ThreeJS', 'React', 'React Three Fiber', 'Web Audio API', 'React Spring'],
    githubUrl: "https://github.com/lazygeekpanda/audio-visualizer-js",
    url: "https://lazygeekpanda.github.io/audio-visualizer-js/"
  },
  {
    id: "product-showcase",
    name: "Product Showcase",
    description: "3D Product showcase with options to change some properties.",
    private: false,
    tech: ['React', 'ThreeJS', 'React', 'React Three Fiber'],
    githubUrl: "https://github.com/lazygeekpanda/product-showcase-3d",
    url: "https://lazygeekpanda.github.io/product-showcase-3d/"
  },
  {
    id: "gerstner",
    name: "Gerstner Wave",
    description: "Ocean wave simulation using Gerstner wave solution",
    private: false,
    tech: ['React', 'ThreeJS', 'React', 'React Three Fiber', 'Styled Components'],
    githubUrl: "https://github.com/lazygeekpanda/gerstner-waves-rf3",
    url: "https://lazygeekpanda.github.io/gerstner-waves-rf3/"
  },
  {
    id: "deck-builder",
    name: "Vessel Deck Builder",
    description:
      "An application to add cargo (containers) to vessel's deck. Select vessel's deck size. Add and move containers using drag & drop.",
    private: true,
    tech: ['React', 'ThreeJS', 'React', 'React Three Fiber', 'MUI', 'Styled Components'],
    githubUrl: "",
    url: ""
  },
  {
    id: "google-docs-clone",
    name: "Google Docs Clone",
    description:
      "Google docs clone with collaboration between multiple users built on top of TinyMCE editor. Collaboration plugin is custom. Client application is communicating with backend through WebSockets only.",
    private: false,
    tech: ['Vue', 'TinyMCE', 'Socket.IO', 'NodeJS', 'Express' ],
    githubUrl: "https://github.com/lazygeekpanda/vue-google-docs-clone",
    url: ""
  },
  {
    id: "epish",
    name: "Epish",
    description:
      "Movie suggestor using OMDB API. It's a simple application, idea was to learn Monorepo using lerna, split controllers, api, web and native app into separate projects and reuse business logic inside web and native apps.",
    private: false,
    tech: ['Lerna', 'React', 'Redux', 'Redux Thunk', 'MUI'],
    githubUrl: "",
    url: "https://movies.kodeo.lt/"
  }
]

const state = {
  experiments
}

export default state
