import { FC } from "react"
import { IoMail, IoLogoGithub, IoLogoLinkedin, IoLogoCodepen } from "react-icons/io5"
import * as S from "./Footer.styled"

const Footer: FC = () => {
  return (
    <S.Footer id="footer">
      <S.Message>
        <h3>Let's work together</h3>
        <p>I will gladly accept your challenge and bring best possible experience</p>

        <S.Contacts>
          <a href="mailto:hello@kodeo.lt">hello@kodeo.lt</a>
          {/* <a href="tel:+37064596220">+3706 45 96 220</a> */}
        </S.Contacts>
        <S.Float>
          <a href="mailto:hello@kodeo.lt" target="_blank" rel="noreferrer">
            <IoMail />
          </a>
          <a
            href="https://www.linkedin.com/in/%C5%BEymantas-venclauskas/"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoLinkedin />
          </a>
          <a href="https://github.com/lazygeekpanda/lazygeekpanda" target="_blank" rel="noreferrer">
            <IoLogoGithub />
          </a>
          <a href="https://codepen.io/lazygeekpanda" target="_blank" rel="noreferrer">
            <IoLogoCodepen />
          </a>
        </S.Float>
      </S.Message>
      <S.Rights>
        <code>© Kodeo | All rights reserved {new Date().getFullYear()}</code>
      </S.Rights>
    </S.Footer>
  )
}

export default Footer
