import { FC } from "react"

import { useSpring, animated } from "@react-spring/web"
import * as S from "./styled"

interface Props {}

const ContactMeButton: FC<Props> = () => {
  const [{ y1, opacity1 }, apiA] = useSpring(() => ({ y1: -50, opacity1: 1 }))
  const [{ y2, opacity2 }, apiB] = useSpring(() => ({ y2: 300, opacity2: 0 }))

  const onMouseEnter = () => {
    apiA.start({ y1: -300, opacity1: 0 })
    apiB.start({ y2: -50, opacity2: 1 })
  }
  const onMouseLeave = () => {
    apiA.start({ y1: -50, opacity1: 1 })
    apiB.start({ y2: 300, opacity2: 0 })
  }

  const onClick = () => {
    document.querySelector('#footer')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <S.ContactMeButton onClick={onClick} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
      <S.TextWrapper>
        <animated.div
          style={{
            transform: y1.to((v: number) => `translateY(${v}%`),
            opacity: opacity1.to((v) => v)
          }}
        >
          Contact Me
        </animated.div>
        <animated.div
          style={{
            transform: y2.to((v: number) => `translateY(${v}%`),
            opacity: opacity2.to((v) => v)
          }}
        >
          Let's talk
        </animated.div>
      </S.TextWrapper>
    </S.ContactMeButton>
  )
}

export default ContactMeButton
