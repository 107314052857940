import colors from 'style/colors'
import styled from "styled-components"

interface SlidesProps {
  backgroundColor: string
}
export const SlidesContainer = styled.div<SlidesProps>`
  height: 100%;
  padding-left: 0;

  background-color: ${({ backgroundColor }) => backgroundColor};

  transition: 2s ease-in-out;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding-left: 300px;
  }
`

export const Slides = styled.div`
  position: relative;

  height: 100%;
`

export const Counter = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);

  font-size: 200px;
  font-weight: 700;

  color: ${colors.white.hex};

  opacity: 0.2;

  @media (min-width: 1200px) {
    font-size: 400px;
    transform: translate(-25%, -50%);
  }
`

export const Pattern = styled.div`
  position: absolute;
  top: 25%;
  left: 18%;

  width: 30vw;
  height: 25vh;

  background-image: radial-gradient(rgba(${colors.dark.rgb}, 1) 1px, transparent 1px);
  background-size: calc(10 * 1px) calc(10 * 1px);
`