import { FC } from "react"

import state from "./store"
import { IoLogoGithub } from "react-icons/io5"
import { FaExternalLinkAlt } from "react-icons/fa"

import * as S from "./styled"

const Experiments: FC = () => {
  return (
    <S.Container>
      <S.TitleWrapper>
        <S.Title>
          Experiments <span></span>
        </S.Title>
        <S.SubTitle>Things I've tried & Proof of Concepts. All Projects can be found at my <a href="https://github.com/lazygeekpanda" target="_blank" rel='noreferrer'>Github Account</a></S.SubTitle>
      </S.TitleWrapper>
      <S.Grid>
        {state.experiments.map((item, index) => (
          <S.GridItem key={index}>
            <div>
              <S.ItemTitle>{item.name}</S.ItemTitle>

              <S.ItemDescription>{item.description}</S.ItemDescription>
            </div>

            <div>
              <p style={{ marginTop: 20 }}>
                <strong style={{ display: "block", fontSize: 12, textTransform: "uppercase" }}>
                  Technologies used:
                </strong>
                <code>{item.tech.join(", ")}</code>
              </p>
              <div style={{ 
                height: 40
              }}>
                {item.private ? (
                  <S.ItemPrivate>
                    <code>Private Repository</code>
                  </S.ItemPrivate>
                ) : (
                  <S.ItemActions>
                    {item.githubUrl && (
                      <S.ItemLink href={item.githubUrl} target="_blank" rel="noreferrer">
                        <IoLogoGithub />
                      </S.ItemLink>
                    )}
                    {item.url && (
                      <S.ItemDemoBtn href={item.url} target="_blank" rel="noreferrer">
                        DEMO
                        <FaExternalLinkAlt />
                      </S.ItemDemoBtn>
                    )}
                  </S.ItemActions>
                )}
              </div>
            </div>
          </S.GridItem>
        ))}
      </S.Grid>
    </S.Container>
  )
}

export default Experiments
